import React, {useState} from 'react';

// plug-ins
import InputMask from 'react-input-mask';

// components
import {useInput} from '../../Components/Hooks/Input';
import Toaster from '../../Components/Toaster';

// models
import {Clients} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty, phoneNormalize} from '../../Globals/Utils';

// globals
import { API } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import logo from './Images/capitalina.svg';

const LoginScreen = () => {
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:code, setValue:setCode, bind:bindCode} = useInput('');
	const [isSMS, setIsSMS] = useState(false);
	const [is404, setIs404] = useState(false);
	const [phoneError, setPhoneError] = useState(false);
	const [codeError, setCodeError] = useState(false);
	const next = () => isSMS ? login() : smsSend();
	const smsSend = async () => {
		clear();
		if (empty(phone)) {
			setPhoneError(true);
			return;
		}
		const res = await Clients.loginCheck(phoneNormalize(phone)).catch((ex) => {
			console.log(666, ex);
			if (ex.code === 404) setIs404(true);
			Toaster.show({message:ex.message||'Ошибка отправки данных',istitle:true});
			setPhoneError(true);
		});
		if (res) {
			setIsSMS(true);
			setTimeout(() => document.getElementById('sms').focus(), 100);
		}
	}
	const login = async () => {
		clear();
		if (empty(code)) {
			setCodeError(true);
			return;
		}
		const res = await Clients.login(phoneNormalize(phone), code).catch((ex) => {
			console.log(666, ex);
			Toaster.show({message:ex.message||'Ошибка отправки данных',istitle:true});
			setCodeError(true);
		});
		if (res) {
			ls('user', res.client);
			ls('token', res.token);
			window.location.href = '/';
		}
	}
	const clear = () => {
		setIs404(false);
		setPhoneError(false);
		setCodeError(false);
	}
	return <div className="login-container-wrapper">
		<div className="login-container">
			<a href={API.site}><img src={logo} alt="ООО МКК  &quot;Капиталина&quot;" className="logo" /></a>
			<div className="login-container-inner">
				<h3>Вход в личный кабинет</h3>
				{isSMS ?
						<div className="form-column">
							<p>На номер <b>{phone}</b> было отправленно СМС-сообщение с кодом для входа в личный кабинет. Введите его в поле ниже.</p>
							<label>
								<InputMask id="sms" mask="999999" placeholder=" " maskPlaceholder={null} {...bindCode} iserror={`${codeError!==undefined&&codeError}`} className="input-panel" />
								<span className="placeholder">Код из СМС</span>
							</label>
						</div>
					:
						<div className="form-column">
							<label>
								<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder=" " maskPlaceholder={null} {...bindPhone} iserror={`${phoneError!==undefined&&phoneError}`} className="input-panel" />
								<span className="placeholder">Мобильный телефон</span>
							</label>
						</div>
				}
				<button onClick={next} className="btn">{isSMS?'Войти':'Далее'}</button>
				{isSMS ?
						<div className="login-container-inner-back" onClick={() => setIsSMS(false)}>Вернуться к вводу номера телефона</div>
					: (is404 ? <div className="login-container-inner-back"><a href={`${API.site}/anketa/`} target="_blank">Регистрация</a></div> : null)
				}
			</div>
		</div>
	</div>;
}

export default LoginScreen;