import React, {useEffect,useState} from 'react';
import  {Redirect} from 'react-router-dom';

// models
import {Requests} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

const AuthScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	useEffect(() => {
		const dataGet = async () => {
			const code = props.match.params.code;
			const res = await Requests.getByCode(code).catch((ex) => console.log(666, ex));
			if (res) {
				ls('token', res.token);
				ls('tokenexp', res.exp);
				ls('user', res.client);
				setUser(res);
				setLoading(false);
			} else ls('user', null);
		};
		dataGet();
	}, []);
	return loading ? null : (user ? <Redirect to={'/dashboard'} /> : <Redirect to={'/login'} />);
}

export default AuthScreen;