// styles
import './styles.css';

const NotFound = () => (
	<div className="notfound">
		<h4>Ничего не найдено!</h4>
		<p>
			Извините, но по вашему запросу ничего не найдено.<br/>
			Для продолжения работы воспользуйтесь меню.
		</p>
	</div>
);
export default NotFound;