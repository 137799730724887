import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// screens
import Start				from './Screens/Start';
import Auth					from './Screens/Auth';

import Login				from './Screens/Login';

import Dashboard			from './Screens/Dashboard';
import Request				from './Screens/Request';
import Anketa				from './Screens/Anketa';
import Credits				from './Screens/Credits';
import Credit				from './Screens/Request';

import Error				from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />
			<Route path='/auth/:code' component={Auth} exact />
			<Route path='/login' component={Login} exact />

			<Route path='/dashboard' component={Dashboard} exact />
			<Route path='/request/:id' component={Request} exact />

			<Route path='/anketa' component={Anketa} exact />

			<Route path='/credits' component={Credits} exact />
			<Route path='/credit/:id' component={Credit} exact />

			<Route path='/500' component={Error} status={500} exact />
			<Route path='/401' component={Error} status={401} exact />
			<Route path='/403' component={Error} status={403} exact />
			<Route path='/404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;