import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const getAll	= async ()		=> await Http.request('ext/contracts', httpMethods.GET);
const get		= async (id)	=> await Http.request(`ext/contract/${id}`, httpMethods.GET);


export {
	getAll,
	get
}