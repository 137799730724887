import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const getAll	= async ()		=> await Http.request('ext/requests', httpMethods.GET);
const get		= async (id)	=> await Http.request(`ext/request/${id}`, httpMethods.GET);

const getByCode	= async (code)	=> await Http.request(`ext/request/auth/${code}`, httpMethods.GET);


export {
	getAll,
	get,
	getByCode
}