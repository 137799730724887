import React, {useEffect,useState} from 'react';

// plug-ins
import moment from 'moment';
import 'moment/locale/ru';

// components
import Template from '../../Components/Template';
import NotFound from '../../Components/NotFound';

// models
import {Requests} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import { countsGet, moneyFormat, phoneFormatter } from '../../Globals/Utils';

// globals
import { requestStatusName, userContactTypeName, userEmploymentName, userGenderName } from '../../Globals/Constants';

// styles
import './styles.css';

const RequestScreen = (props) => {
	const [id, setId] = useState(props.match.params.id);
	const [request, setRequest] = useState(null);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const requests = await Requests.get(id).catch((ex) => {
				console.log(666, ex);
			});
			if (requests) setRequest(requests);
		};
		dataGet();
		setLoading(false);
	}, []);
	return (
		<Template title={`Заявка${request?` от ${moment(request.createdAt).format('lll')}`:''}`} page={'requests'}>
			{loading ? null :
				<div className="request-container">
					{request  ?
							<div className="request-info">
								<div className="request-info-description">
									<div className="request-info-row">
										<span>Статус</span>
										<span>{requestStatusName[request.status]}</span>
									</div>
									<div className="request-info-row">
										<span>Сумма заявки</span>
										<span>{moneyFormat(request.amount, false)} ₽</span>
									</div>
									<div className="request-info-row">
										<span>Срок займа</span>
										<span>{countsGet.days(request.period)}</span>
									</div>
									<div className="request-info-row">
										<span>Процентная ставка</span>
										<span>{request.percent}%</span>
									</div>
									<div className="request-info-row">
										<span>Сумма к возврату</span>
										<span>{moneyFormat(request.amountCredit, false)} ₽</span>
									</div>
								</div>
								<div className="request-rows">
									<div className="request-row">
										<span>Фамилия</span>
										<span>{request.lastName}</span>
									</div>
									<div className="request-row">
										<span>Имя</span>
										<span>{request.firstName}</span>
									</div>
									<div className="request-row">
										<span>Отчество</span>
										<span>{request.middleName||'—'}</span>
									</div>
									<div className="request-row">
										<span>Дата рождения</span>
										<span>{moment(request.birthDate).format('ll')}</span>
									</div>
									<div className="request-row">
										<span>Место рождения</span>
										<span>{request.birthPlace}</span>
									</div>
									<div className="request-row">
										<span>Пол</span>
										<span>{userGenderName[request.gender]}</span>
									</div>
									<div className="request-row">
										<span>Серия паспорта</span>
										<span>{request.passportSeries}</span>
									</div>
									<div className="request-row">
										<span>Номер паспорта</span>
										<span>{request.passportNumber}</span>
									</div>
									<div className="request-row">
										<span>Кем выдан</span>
										<span>{request.passportIssuer}</span>
									</div>
									<div className="request-row">
										<span>Дата выдачи</span>
										<span>{moment(request.passportDate).format('ll')}</span>
									</div>
									<div className="request-row">
										<span>Код подразделения</span>
										<span>{request.passportCode}</span>
									</div>
									<div className="request-row">
										<span>ИНН</span>
										<span>{request.INN}</span>
									</div>
									<div className="request-row">
										<span>СНИЛС</span>
										<span>{request.SNILS}</span>
									</div>
									<div className="request-row">
										<span>Адрес регистрации</span>
										<span>{request.addressRegistration}</span>
									</div>
									<div className="request-row">
										<span>Адрес проживания</span>
										<span>{request.addressLive}</span>
									</div>
									<div className="request-row">
										<span>Тип заработка</span>
										<span>{request.workType ? userEmploymentName[request.workType] : '—'}</span>
									</div>
									<div className="request-row">
										<span>Среднемесячный доход</span>
										<span>{request.incomeAverage ? `${moneyFormat(request.incomeAverage, false)} ₽`: '—'}</span>
									</div>
									{request.workName ?
										<div className="request-row">
											<span>Название организации</span>
											<span>{request.workName}</span>
										</div> : null}
									{request.workPosition ?
										<div className="request-row">
											<span>Должность</span>
											<span>{request.workPosition}</span>
										</div> : null}
									{request.workAddress ?
										<div className="request-row">
											<span>Адрес организации</span>
											<span>{request.workAddress}</span>
										</div> : null}
									{request.workPhone ?
										<div className="request-row">
											<span>Телефон организации</span>
											<span>{phoneFormatter(request.workPhone)}</span>
										</div> : null}
									{request.contactFirstName ?
										<div className="request-row">
											<span>Имя контакта</span>
											<span>{request.contactFirstName}</span>
										</div> : null}
									{request.contactLastName ?
										<div className="request-row">
											<span>Фамилия контакта</span>
											<span>{request.contactLastName}</span>
										</div> : null}
									{request.contactMiddleName ?
										<div className="request-row">
											<span>Отчество контакта</span>
											<span>{request.contactMiddleName}</span>
										</div> : null}
									{request.contactPhone ?
										<div className="request-row">
											<span>Телефон контакта</span>
											<span>{phoneFormatter(request.contactPhone)}</span>
										</div> : null}
									{request.contactType ?
										<div className="request-row">
											<span>Кем приходится контакт</span>
											<span>{userContactTypeName[request.contactType]}</span>
										</div> : null}
									<div className="request-row">
										<span>Номер карты для перевода</span>
										<span>{request.creditCardNumber}</span>
									</div>
									<div className="request-row">
										<span>Мобильный телефон</span>
										<span>{phoneFormatter(request.phone)}</span>
									</div>
									<div className="request-row">
										<span>E-mail</span>
										<span>{request.email||'—'}</span>
									</div>
									<div className="request-row request-row-photos">
										<span>Ваш портрет с паспортом</span>
										<img src={request.photos[0].data} alt="Фотография паспорта" />
									</div>
									<div className="request-row request-row-photos">
										<span>Фото паспорта (основная страница)</span>
										<img src={request.photos[1].data} alt="Фотография паспорта" />
									</div>
									<div className="request-row request-row-photos">
										<span>Фото паспорта (страница с регистрацией)</span>
										<img src={request.photos[2].data} alt="Фотография паспорта" />
									</div>
									<div className="request-row request-row-photos">
										<span>Фото банковской карты</span>
										<img src={request.photos[3].data} alt="Фотография паспорта" />
									</div>
								</div>
							</div>
						: <NotFound />
					}
				</div>
			}
		</Template>
	);
};

export default RequestScreen;