import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Loader from '../Loader';

// helpers
import { ls } from '../../Globals/Localstorage';

// styles
import './styles.css';

// images
import logo from './Images/capitalina-logo.svg';
import logout from './Images/logout.svg';
import loader from './Images/loader.svg';

const Template = (props) => {
	const [Modal, open, close] = useModal('root', {preventScroll:true});
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const done = () => {
		ls();
		close();
		window.location.href='/login';
	}
	const show = (e) => {
		e.preventDefault();
		open();
	}
	const hide = (e) => {
		e.preventDefault();
		close();
	}
	useEffect(() => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/403';
			return;
		}
		setUser(user);
		setLoading(false);
	}, []);
	const isselect = (page) => props.page === page ? 'selected' : null;
	return loading ? <Loader /> :
		<>
			<div className="wrapper">
				<div className="template-container">
					<div className="template-menu">
						<div className="template-menu-inner">
							<div className="template-menu-logo">
								<img src={logo} alt="ООО МКК  &quot;Капиталина&quot;" />
							</div>
							<div className="status-loader">
								<img src={loader} alt="Загрузка" />
								<span>Ожидайте решения</span>
							</div>
						</div>
					</div>
					<div className="template-main">
						<ul className="menu">
							<li className={isselect('requests')}><Link to="/">Заявки</Link></li>
							<li className={isselect('anketa')}><Link to="/anketa">Анкета</Link></li>
							<li className={isselect('credits')}><Link to="/credits">Займы</Link></li>
							{/* <li className={isselect('rating')}><Link to="/rating">Кредитный рейтинг</Link></li> */}
							<li className="menu-last">
								<span>{user.name}</span>
								<img src={logout} alt="Выход" onClick={show} />
							</li>
						</ul>
						<div className="template-main-content">
							<h2>{props.title}</h2>
							{props.children}
						</div>
					</div>
				</div>
			</div>
			<Modal>
				<div className="confirmcontainer">
					<h4>Подтвердите пожалуйста</h4>
					Вы уверены что хотите выйти из личного кабинета?<br/>
					Вы можете повторно войти в личный кабинет используя мобильный телефон.
					<div className="buttons">
						<button onClick={done}>Да</button>
						<Link to={'/'} className="cancel" onClick={hide}>Отмена</Link>
					</div>
				</div>
			</Modal>
		</>;
};
export default Template;