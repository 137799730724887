import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import moment from 'moment';
import 'moment/locale/ru';

// components
import Template from '../../Components/Template';
import NotFound from '../../Components/NotFound';

// models
import {Requests} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import { countsGet, moneyFormat } from '../../Globals/Utils';

// globals
import { requestStatusName } from '../../Globals/Constants';

// styles
import './styles.css';

const DashboardScreen = () => {
	const [requests, setRequests] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const requests = await Requests.getAll().catch((ex) => console.log(666, ex));
			setRequests(requests);
		};
		dataGet();
		setLoading(false);
	}, []);
	return (
		<Template title={'Заявки'} page={'requests'}>
			{loading ? null :
				<div className="dashboard-container">
					{requests && requests.length ?
							<div className="dashboard-requests">
								{requests.map((v,i) => <div key={i} className="dashboard-request">
									<div className="dashboard-request-title">
										<div>Заявка от <b>{moment(v.createdAt).format('lll')}</b></div>
										<span>{requestStatusName[v.status]}</span>
									</div>
									<div className="dashboard-request-description">
										<div className="dashboard-request-row">
											<span>Сумма заявки</span>
											<span>{moneyFormat(v.amount, false)} ₽</span>
										</div>
										<div className="dashboard-request-row">
											<span>Срок займа</span>
											<span>{countsGet.days(v.period)}</span>
										</div>
										<div className="dashboard-request-row">
											<span>Процентная ставка</span>
											<span>{v.percent}%</span>
										</div>
										<div className="dashboard-request-row">
											<span>Сумма к возврату</span>
											<span>{moneyFormat(v.amountCredit, false)} ₽</span>
										</div>
									</div>
									<div className="dashboard-request-button">
										<Link to={`/request/${v._id}`}>Подробнее</Link>
									</div>
								</div>)}
							</div>
						: <NotFound />
					}
				</div>
			}
		</Template>
	);
};

export default DashboardScreen;