import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import moment from 'moment';
import 'moment/locale/ru';

// components
import Template from '../../Components/Template';
import NotFound from '../../Components/NotFound';

// models
import {Contracts} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import { countsGet, moneyFormat } from '../../Globals/Utils';

// globals
import { requestStatusName } from '../../Globals/Constants';

// styles
import './styles.css';

const CreditsScreen = () => {
	const [credits, setCredits] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const credits = await Contracts.getAll().catch((ex) => console.log(666, ex));
			setCredits(credits);
		};
		dataGet();
		setLoading(false);
	}, []);
	return (
		<Template title={'Займы'} page={'credits'}>
			{loading ? null :
				<div className="credits-container">
					{credits && credits.length ?
							<div className="credits-requests">
								{credits.map((v,i) => <div key={i} className="credits-request">
									<div className="credits-request-title">
										<div>Заявка от <b>{moment(v.createdAt).format('lll')}</b></div>
										<span>{requestStatusName[v.status]}</span>
									</div>
									<div className="credits-request-description">
										<div className="credits-request-row">
											<span>Сумма заявки</span>
											<span>{moneyFormat(v.amount, false)} ₽</span>
										</div>
										<div className="credits-request-row">
											<span>Срок займа</span>
											<span>{countsGet.days(v.period)}</span>
										</div>
										<div className="credits-request-row">
											<span>Процентная ставка</span>
											<span>{v.percent}%</span>
										</div>
										<div className="credits-request-row">
											<span>Сумма к возврату</span>
											<span>{moneyFormat(v.amountCredit, false)} ₽</span>
										</div>
									</div>
									<div className="credits-request-button">
										<Link to={`/credit/${v._id}`}>Подробнее</Link>
									</div>
								</div>)}
							</div>
						: <NotFound />
					}
				</div>
			}
		</Template>
	);
};

export default CreditsScreen;