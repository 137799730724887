const isDevelopmentMode = process.env.NODE_ENV !== 'production';

const url = {
	api			: isDevelopmentMode ? 'http://localhost:8090' : 'https://api.capitalina-mkk.ru',
	site		: isDevelopmentMode ? 'http://localhost:3004' : 'https://capitalina-mkk.ru'
};

const API = {
	url			: `${url.api}/api/v1/`,
	site		: url.site,
	key			: 'J<DxyN+YT^$d_//=eq{^6?/@(FSd+5{:D,/BUcqAH]{Okl281.G>?rYf4?ar-KN',
	version		: '1.0.1'
};

const httpMethods = {
	GET		: 'GET',
	POST	: 'POST',
	PUT		: 'PUT',
	DELETE	: 'DELETE'
};

const commonStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1
};
const commonStatusName = ['Не активен','Активен'];

/* Client */

const userGender = {
	UNSET		: 0,
	MALE		: 1,
	FEMALE		: 2
};
const userGenderName = ['','Мужской','Женский'];

const userEmployment = {
	UNSET				: 0,
	UNEMPLOYED			: 1,
	BUDGET				: 2,
	IP					: 3,
	OAO					: 4,
	WORKING_PENSIONER	: 5,
	SELF_EMPLOYED		: 6
};
const userEmploymentName = ['','Работающий','Безработный','Бюджетная организация','ИП','ОАО,ООО,ЗАО','Работающий пенсионер','Самозанятый'];

const userContactType = {
	UNSET		: 0,
	WIFE		: 1,
	CHILD		: 2,
	PARENT		: 3,
	RELATIVE	: 4,
	COLLEAGUE	: 5,
	FRIEND		: 6,
	OTHER		: 100
};
const userContactTypeName = ['','Жена/Муж','Ребенок','Родитель','Родственник','Коллега','Друг/Подруга','Другое'];

const requestStatus = {
	NEW		: 0,
	PROCESS	: 1,
	CHECK	: 2,
	DONE	: 3,
	FAIL	: 4
};
const requestStatusName = ['Новая заявка','Заявка в обработке','Заявка на проверке','Заявка исполнена','Заявка отклонена'];

export {
	API,
	httpMethods,
	commonStatus,
	commonStatusName,
	userGender,
	userGenderName,
	userEmployment,
	userEmploymentName,
	userContactType,
	userContactTypeName,
	requestStatus,
	requestStatusName
};