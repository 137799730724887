import React, {useEffect,useState} from 'react';

// plug-ins
import moment from 'moment';
import 'moment/locale/ru';

// components
import Template from '../../Components/Template';

// helpers
import {ls} from '../../Globals/Localstorage';
import { moneyFormat, phoneFormatter } from '../../Globals/Utils';

// globals
import { userContactTypeName, userEmploymentName, userGenderName } from '../../Globals/Constants';

// styles
import './styles.css';

const AnketaScreen = () => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			setUser(user);
		};
		dataGet();
		setLoading(false);
	}, []);
	return (
		<Template title={'Анкета'} page={'anketa'}>
			{loading || !user ? null :
				<div className="client-container">
					<div className="client-info">
						<div className="client-rows">
							<div className="client-row">
								<span>Фамилия</span>
								<span>{user.lastName}</span>
							</div>
							<div className="client-row">
								<span>Имя</span>
								<span>{user.firstName}</span>
							</div>
							<div className="client-row">
								<span>Отчество</span>
								<span>{user.middleName||'—'}</span>
							</div>
							<div className="client-row">
								<span>Дата рождения</span>
								<span>{moment(user.birthDate).format('ll')}</span>
							</div>
							<div className="client-row">
								<span>Место рождения</span>
								<span>{user.birthPlace}</span>
							</div>
							<div className="client-row">
								<span>Пол</span>
								<span>{userGenderName[user.gender]}</span>
							</div>
							<div className="client-row">
								<span>Серия паспорта</span>
								<span>{user.passportSeries}</span>
							</div>
							<div className="client-row">
								<span>Номер паспорта</span>
								<span>{user.passportNumber}</span>
							</div>
							<div className="client-row">
								<span>Кем выдан</span>
								<span>{user.passportIssuer}</span>
							</div>
							<div className="client-row">
								<span>Дата выдачи</span>
								<span>{moment(user.passportDate).format('ll')}</span>
							</div>
							<div className="client-row">
								<span>Код подразделения</span>
								<span>{user.passportCode}</span>
							</div>
							<div className="client-row">
								<span>ИНН</span>
								<span>{user.INN}</span>
							</div>
							<div className="client-row">
								<span>СНИЛС</span>
								<span>{user.SNILS}</span>
							</div>
							<div className="client-row">
								<span>Адрес регистрации</span>
								<span>{user.addressRegistration}</span>
							</div>
							<div className="client-row">
								<span>Адрес проживания</span>
								<span>{user.addressLive}</span>
							</div>
							<div className="client-row">
								<span>Тип заработка</span>
								<span>{user.workType ? userEmploymentName[user.workType] : '—'}</span>
							</div>
							<div className="client-row">
								<span>Среднемесячный доход</span>
								<span>{user.incomeAverage ? `${moneyFormat(user.incomeAverage, false)} ₽`: '—'}</span>
							</div>
							{user.workName ?
								<div className="client-row">
									<span>Название организации</span>
									<span>{user.workName}</span>
								</div> : null}
							{user.workPosition ?
								<div className="client-row">
									<span>Должность</span>
									<span>{user.workPosition}</span>
								</div> : null}
							{user.workAddress ?
								<div className="client-row">
									<span>Адрес организации</span>
									<span>{user.workAddress}</span>
								</div> : null}
							{user.workPhone ?
								<div className="client-row">
									<span>Телефон организации</span>
									<span>{phoneFormatter(user.workPhone)}</span>
								</div> : null}
							{user.contactFirstName ?
								<div className="client-row">
									<span>Имя контакта</span>
									<span>{user.contactFirstName}</span>
								</div> : null}
							{user.contactLastName ?
								<div className="client-row">
									<span>Фамилия контакта</span>
									<span>{user.contactLastName}</span>
								</div> : null}
							{user.contactMiddleName ?
								<div className="client-row">
									<span>Отчество контакта</span>
									<span>{user.contactMiddleName}</span>
								</div> : null}
							{user.contactPhone ?
								<div className="client-row">
									<span>Телефон контакта</span>
									<span>{phoneFormatter(user.contactPhone)}</span>
								</div> : null}
							{user.contactType ?
								<div className="client-row">
									<span>Кем приходится контакт</span>
									<span>{userContactTypeName[user.contactType]}</span>
								</div> : null}
							<div className="client-row">
								<span>Номер карты для перевода</span>
								<span>{user.creditCardNumber}</span>
							</div>
							<div className="client-row">
								<span>Мобильный телефон</span>
								<span>{phoneFormatter(user.phone)}</span>
							</div>
							<div className="client-row">
								<span>E-mail</span>
								<span>{user.email||'—'}</span>
							</div>
							<div className="client-row client-row-photos">
								<span>Ваш портрет с паспортом</span>
								<img src={user.photos[0].data} alt="Фотография паспорта" />
							</div>
							<div className="client-row client-row-photos">
								<span>Фото паспорта (основная страница)</span>
								<img src={user.photos[1].data} alt="Фотография паспорта" />
							</div>
							<div className="client-row client-row-photos">
								<span>Фото паспорта (страница с регистрацией)</span>
								<img src={user.photos[2].data} alt="Фотография паспорта" />
							</div>
						</div>
					</div>
				</div>
			}
		</Template>
	);
};

export default AnketaScreen;